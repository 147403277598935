import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom";
import { ShoppingCartOutline, MenuOutline } from '@styled-icons/evaicons-outline'
import styled from "styled-components";
import { useSelector } from "react-redux";
import { topBarItems } from '../lib/constants';
import TopBarMenu from "./TopBarMenu";

const CartIcon = styled(ShoppingCartOutline)`
    color: black;
    width: 32px;
    height: 32px;
    @media only screen and (min-width: 768px) {
        width: 56px;
        height: 56px;
    }
`

const MenuIcon = styled(MenuOutline)`
    color: black;
    width: 32px;
    height: 32px;
    margin: 0 16px;
    @media only screen and (min-width: 768px) {
        display: none;
    }
`

const CheckoutCounter = styled.div`
    background-color: #6A62EA;
    color: white;
    height: 14px;
    width: 14px;
    border-radius: 100%;
    position: absolute;
    top: -8px;
    left: 20px;
    @media only screen and (min-width: 768px) {
        height: 18px;
        width: 18px;
        top: -4px;
        left: 44px;
    }
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const TopBar = () => {
    const topBarRef = useRef();
    const [lastScrollPosition, setLastScrollPosition] = useState(0);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const shoppingCart = useSelector(state => state.checkout.shoppingCart);
    
    const onScroll = () => {
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        if (currentScrollPosition > lastScrollPosition) {
            topBarRef.current.classList.remove('move-down');
            topBarRef.current.classList.add('move-up'); 
        } else {
            topBarRef.current.classList.remove('move-up');
            topBarRef.current.classList.add('move-down'); 
        }
        setLastScrollPosition(currentScrollPosition <= 0 ? 0 : currentScrollPosition);
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll, false)
        return () => window.removeEventListener('scroll', onScroll, false);
    })

    return(<>
        <div ref={topBarRef} className="top-bar-container">
            <MenuIcon onClick={() => setIsMenuOpen(!isMenuOpen)}/>
            <Link className="top-bar-title" to="/" ><h1>Pink is 4 Everyone</h1></Link>
            {topBarItems.map((item, i)  => <Link key={`navbar-item-${i}`} className="top-bar-item" to={item.href}><h3>~~ {item.title} ~~</h3></Link>)}

            <Link to="/checkout" className="top-bar-checkout">
                <div style={{position: 'relative'}}>
                    <CartIcon/>
                    <CheckoutCounter>{shoppingCart.length}</CheckoutCounter>
                </div>
            </Link>
        </div>
        {isMenuOpen && 
          <TopBarMenu 
            items={[{ title: 'Home', href: '/'}, ...topBarItems]} 
            onItemClick={() => setIsMenuOpen(!isMenuOpen)} />}
    </>
    )
}

export default TopBar;