function move(array, oldIndex, newIndex) {
    if (newIndex >= array.length) {
      newIndex = array.length - 1;
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    return array;
}

export function moveElement(array, index, offset) {
    const newIndex = index + offset;
  
    return move(array, index, newIndex);
}
const token = (process.env.NODE_ENV === 'development' || window.location.href === 'http://test.pinkis4everyone.com/') ? 
  process.env.REACT_APP_MOLLIE_TEST : process.env.REACT_APP_MOLLIE_LIVE;

export const configureRequest = (method, route = '', data ) => {
  return {
      method,
      url: `${process.env.REACT_APP_PROXY_URL}/${process.env.REACT_APP_MOLLIE_BASE_URL}/${route}`,
      headers: { 
          'Authorization': `Bearer ${token}`, 
          'Content-Type': 'application/x-www-form-urlencoded'
      },
      data
  }
};

export function getTotalPrice(products) {
  return products.reduce((acc, curr) => acc + Number(curr.acf.price), 0).toFixed(2).toString();
}

export const formatPrice = (price) => price?.toFixed(2).toString();
const calculateVatAmount = (price) => price * (.21 / 1.21);

export const addProductLine = (product, quantity = 1) => {
  if(product) {
    const {acf: productDetails} = product;
    const vat = calculateVatAmount(productDetails.price * quantity);
    const totalAmount = productDetails.price * quantity;
    return {  
      name: productDetails.title,
      quantity,
      unitPrice: {
        currency: 'EUR',
        value: Number(productDetails.price).toFixed(2)
      },
      totalAmount: {
        currency: 'EUR',
        value: Number(totalAmount).toFixed(2)
      },
      vatRate: '21.00',
      vatAmount: {
        currency: 'EUR',
        value: Number(vat).toFixed(2)
      }
    }
  }
  return undefined;
}