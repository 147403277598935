import styled from "styled-components";

export const CheckoutContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    padding: 72px 16px;
    @media only screen and (min-width: 768px) { 
        padding-top: 144px;
        padding-left: 128px;
        padding-right: 128px;
        margin-right: auto;
        flex-direction: row;
    }
`

export const ShoppingCartList = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    color: white;
    ul {
        width: 100%;
        padding: 0;
    }
    @media only screen and (min-width: 768px) {
        max-width: 100%;
        margin-left: 54px;
    }
`

export const ShoppingCartListItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-align: left;
    color: white;
    border-bottom: 1px dotted black;
    margin-bottom: 16px;
    padding-bottom: 8px;
    button {
        background-color: #ec5990;
        border-radius: 10%;
        border: 1px solid black;
        margin-left: 8px;
    }
    p {
        font-size: 24px; 
        font-weight: 'bold';
    }
    h2 {
        font-size: 14px;
    }
    @media only screen and (min-width: 768px) {
        h2 {
            font-size: 20px;
        }
    }
    img {
        margin-right: 8px;
    }
`

export const ShoppingCartListTotal = styled.li`
    display: flex;
    align-items: center;
    text-align: left;
    color: #ec5990;
    border-bottom: 1px dotted black;
    h2 {
        margin-right: 8px;
    }
    @media only screen and (min-width: 768px) {
        max-width: 25%; 
        font-size: 28px;
        
    }

`

export const CheckoutForm = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 32px;
`