import { Grid } from "../styles/Grid.style";
import ProductCard from "../components/ProductCard";
import FilterList from "../components/FilterList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { filterByCategory, getProducts } from "../actions/checkout";

export default function Shop() {
    const products = useSelector(state => state.checkout.filteredProducts);
    const dispatch = useDispatch();
    const [selectedCategory, setSelectedCategory] = useState('All');

    useEffect(() => {
        if(!products) {
            dispatch(getProducts())
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])

    const handleCategoryClick = (category) => {
        setSelectedCategory(category)
        dispatch(filterByCategory(category));
    }

    return(
        <Grid>
            <FilterList selectedCategory={selectedCategory} onCategoryClick={handleCategoryClick}/>
            {products && products.map(product => (
                    <ProductCard key={product.id} product={product}/>
            ))}
            {products?.length === 0 && <><div role="cell"/><h1>No products found for this category</h1></>} 
        </Grid>
    )
}