import { categories } from "../lib/constants"
import { RadioButtonOffOutline, RadioButtonOnOutline} from '@styled-icons/evaicons-outline'
import styled from "styled-components"

const RadioButtonOff = styled(RadioButtonOffOutline)`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  cursor: pointer;
`

const RadioButtonOn = styled(RadioButtonOnOutline)`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`

const FilterListContainer = styled.div`
    min-height: 100%;
    padding: 0 32px 32px 32px;
    ul {
        text-align: left;
    }
    li {
        font-size: 24px;
        cursor: pointer;
    }

`
const FilterList = ({selectedCategory, onCategoryClick}) => {
    return(
        <FilterListContainer>
            <h1>Filter products by category</h1>
            <ul style={{textAlign: 'left'}}>
                {categories.map((category, i) => <div key={`category-${i}`} style={{display: 'flex', alignItems: 'center'}} onClick={() => onCategoryClick(category)}>
                    {selectedCategory === category ?  <RadioButtonOn/> : <RadioButtonOff/>}
                    <li style={{fontSize: 24}}>{category}</li>
                </div>
                )
            }
            </ul>
        </FilterListContainer>
    )
}

export default FilterList;