import Modal from 'react-modal';
import styled from 'styled-components';

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 32px;
    a {
        color: #6A62EA;
        font-weight: 700;
        margin-right: 16px;
        @media only screen and (min-width: 768px) { 
            margin-right: 32px;
        } 
    }
`
const ModalWrapper = ({isOpen, overlayColor, children, }) => {
    const customStyles = {
        overlay: {
            backgroundColor: overlayColor || 'rgb(0, 0, 0, 0.75)',
        },

    };
      
    return (
        <Modal 
            isOpen={isOpen}
            style={customStyles}
            className="modal-wrapper"
        >
            <ModalContent>
                {children}
            </ModalContent>
        </Modal>
    );
}

export default ModalWrapper;