import styled from 'styled-components';

export const ProductInfo = styled.div`
    position: absolute;
    background-color: rgba(255,255,255,0.75);
    width: 100%;
    border-bottom: 1px solid black;
    padding: 16px 0;
    h2 {
        color: #6A62EA;
    }
    @media only screen and (min-width: 768px) { 
      padding: 32px 0;
    }
`

export const ProductModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    height: 100%;
    color: #eee;
    border-radius: .75rem;
    padding-top: 64px;
    margin: 0px auto;
    background-opacity; 1;
    @media only screen and (min-width: 768px) {
        align-items: flex-start;
        flex-direction: row;
        background-color: transparent;
        text-align: left;
        padding: 0 32px;
        padding-top: 164px;
        margin-top: auto;
    }
`

export const ProductImage = styled.img`
  max-width: 320px;
  object-fit: cover;
  border: 1px solid black;
  @media only screen and (min-width: 768px) {
    max-width: 250px;
    max-height: 500px;
  }
  
`

export const CheckoutCTA = styled.div`
  display: flex;
  margin-top: auto;
  justify-content: space-between;
  img {
    max-width: 40px;
    margin-left: 8px;
    cursor: pointer;
    object-fit: contain;
  }
`

export const ProductInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex: 2;
    padding: 0 32px;
    @media only screen and (min-width: 768px) {
      min-height: 500px;
    }
`

export const ProductModalCloseBtn = styled.img`
  max-width: 56px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
`

export const CheckoutInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: grey;
  color: white;
  box-shadow: 10px 10px 8px #888888;
  border-radius: .75rem;
  border: 1px solid pink;
  max-height: 200px;
  p {
    font-size: 24px;
  }
`

export const ProductPriceText = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100%;
  border: 3px solid white;
  background-color: #ec5990;
  padding: 16px;
  color: white;
  margin-left: 48px;
  p {
    font-size: 24px;
  }
`

export const CheckoutButton = styled.button`
  width: 200px;
  background-color: white;
  color: magenta;
  font-size: 24px;
  border: 3px solid #eeeeee;
  border-radius: 10px;
  cursor: pointer;
  height: 75%;
  margin-top: 12px;
  padding: 8px 0;
`

export const OtherImagesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-row: 1fr 1fr;
  grid-gap: 4px;
  width: 100%;
  padding: 20px 0;
  @media only screen and (min-width: 768px) {
    display: flex;
    margin-top: auto;
    align-items: start;
  }
  img {
    object-fit: cover;
    max-width: 175px;
    max-height: 175px;
    margin-right: 8px;
  }
`

export const OtherProductsContainer = styled.div`
  flex: 1;
  text-align: center;
  background-color: white;
  color: #eee;
  box-shadow: 10px 10px 8px #888888;
  border-radius: .75rem;
  border: 1px solid grey;
  margin: 32px;
  padding: 20px;
  p {
    font-size: 24px;
  }
  h3 {
    padding: 0;
    margin: 0;
  }
  img {
    margin: 8px;
  }
  @media only screen and (min-width: 768px) { 
    margin: 32px 0;
  }
`

export const OtherProductsImageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
`