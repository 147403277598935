import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPageById } from "../actions/page";
import { HomePageContainer, HomePageContent } from "../styles/Page.style";

export default function Home() {
    const homePage = useSelector(state => state.page.singlePage);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [backgroundImages, setBackgroundImages] = useState([]);
    const imageCount = backgroundImages?.length - 1;
    const dispatch = useDispatch()
    useEffect(() => {
        if(!homePage) {
            dispatch(getPageById(155));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [homePage]);

    useEffect(() => {
        if(homePage?.acf) {
            const bgImages = Object.values(homePage?.acf).filter(entry => entry);
            setBackgroundImages(bgImages);
        }
    }, [homePage?.acf])
    
    useEffect(() => {
        let intervalId = setInterval(() => {
            if(currentImageIndex < imageCount) {
                setCurrentImageIndex(currentImageIndex + 1);
            } else {
                setCurrentImageIndex(0);
            }
        },8000)

        return(() => {
            clearInterval(intervalId)
        })
    },[currentImageIndex, imageCount])

    return(
        <>
            {backgroundImages && backgroundImages[currentImageIndex] &&
                <HomePageContainer bgImage={backgroundImages[currentImageIndex].url} height={1240}>
                    <div className="my-64">
                        {homePage && <HomePageContent dangerouslySetInnerHTML={{ __html: homePage.content.rendered }}/>}
                    </div>
                </HomePageContainer>}
        </>
    )
}