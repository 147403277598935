import axios from "axios";
import { configureRequest } from "../lib/helpers";

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const ORDER_SUCCESS = 'ORDER_SUCCESS';
export const PROCESSING_ORDER = 'PROCESSING_ORDER';
export const ORDER_ERROR = 'ORDER_ERROR';
export const SET_OTHER_PRODUCTS = 'SET_OTHER_PRODUCTS';
export const SET_USER_INFO = 'SET_USER_INFO';
export const FILTER_BY_CATEGORY = 'FILTER_BY_CATEGORY';

export const setProducts = (payload) => {
    return {
        type: 'SET_PRODUCTS',
        payload
    }
}

export const setOtherProducts = (payload) => {
    return {
        type: 'SET_OTHER_PRODUCTS',
        payload
    }
}

export const addToCart = (payload) => {
    return {
        type: 'ADD_TO_CART',
        payload
    }
}

export const removeFromCart = (payload) => {
    return {
        type: 'REMOVE_FROM_CART',
        payload
    }
}

export const orderSuccess = (checkoutLink) => {
  return {
    type: 'ORDER_SUCCESS',
    payload: checkoutLink
  }
}

export const setUserInfo = (formData) => {
    return {
        type: 'SET_USER_INFO',
        payload: formData
    }
}

export const filterByCategory = (categoryName) => {
    return {
        type: 'FILTER_BY_CATEGORY',
        payload: categoryName
    }
}

export const placeOrder = (body) => {
    const config = configureRequest('post', 'orders', body);
    return (dispatch) => {
      return axios(config)
        .then(response => {
            if(response.data._links.checkout) {
                dispatch(orderSuccess(response.data._links.checkout.href))
            } else {
                dispatch({type: 'ORDER_ERROR'});
            }
        })
        .catch(err => console.error(err));
    }
}

export const getProducts = (amount = 100) => {
    return (dispatch) => {
        return axios.get(`${process.env.REACT_APP_WP_BASE_URL}/wp/v2/product?per_page=${amount}`)
                    .then(res => dispatch(setProducts(res.data)))
                    .catch(err => console.error(err))
    }
}
