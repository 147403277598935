import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../actions/checkout";
import { formFields } from '../lib/constants';

const CheckoutForm = ({fireOrder}) => {
    const { register, handleSubmit, formState } = useForm();
    console.log(formState.errors)
    const dispatch = useDispatch();

    function onSubmit(formValues) {
        dispatch(setUserInfo(formValues))
        fireOrder(formValues);
    }

    function getFieldError(fieldKey, fieldLabel) {
      const fieldError = formState.errors[fieldKey];
      const errorStyles = {color: '#ec5990', fontSize: 12, paddingBottom: 12}
      if(fieldError && fieldError.type === 'pattern') {
        return <p style={errorStyles}>{fieldError.message}</p>;
      }
      if(fieldError && fieldError.type === 'required') {
        return <p style={errorStyles}>{fieldLabel} is required</p>
      }
      return <></>;
    }

    return(
        <form className="checkout-form" onSubmit={handleSubmit(onSubmit)}>
            <h2 className="checkout-form-title">Shipping information</h2>
            {formFields && formFields.map(field => 
                <div key={field.key}>
                    <label>{field.label}</label>
                    {field.type !== 'select' && <input {...register(field.key, {...field.validation})} type={field.type} placeholder={field.label}/>}
                    {field.type === 'select' && <select {...register(field.key, {...field.validation})}>
                            {field.options.map(option => <option key={option.code} value={option.code}>{option.name}</option>)}
                        </select>}
                    {getFieldError(field.key, field.label)}
                </div>    
            )}
            <button type="submit">Submit order</button>
        </form>
    )
}

export default CheckoutForm;