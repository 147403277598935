import styled from 'styled-components';

export const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    min-height: 100vh;
    padding-top: 100px;
    grid-gap: 8px;
    @media only screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
    }
`;

export const GridImage = styled.div`
  flex-grow: 1;
  cursor: pointer;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${props => `url("${props.src}")`};
  background-size: cover;
  background-position: 50%;
`;

export const GridItemWrapper = styled.div`
  flex: 0 0 25%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: stretch;
  box-sizing: border-box;
  :before {
    content: "";
    display: table;
    padding-top: 100%;
  }
`;