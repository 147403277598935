import { ADD_TO_CART, SET_PRODUCTS, ORDER_SUCCESS, ORDER_ERROR, REMOVE_FROM_CART, SET_OTHER_PRODUCTS, SET_USER_INFO, FILTER_BY_CATEGORY, PROCESSING_ORDER } from "../actions/checkout";

export default function checkout(state = {
    products: [],
    shoppingCart: []
}, action = {}) {
    switch (action.type) {
        case SET_PRODUCTS: 
            return {...state, products: action.payload, filteredProducts: [...action.payload]};
        case FILTER_BY_CATEGORY:
            if(action.payload !== 'All') {
                const filtered = state.products.filter(product => product.acf.category === action.payload);
                return {...state, filteredProducts: filtered};
            }
            return {...state, filteredProducts: [...state.products]}
        case SET_OTHER_PRODUCTS:
            function getRandomInt(min, max) {
                min = Math.ceil(min);
                max = Math.floor(max);
                return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
            }

            const randomIndex = getRandomInt(0, state.products.length - 4);

            return {
                ...state,
                otherProducts: state.products.filter(product => product.id !== action.payload).slice(randomIndex, randomIndex + 4)
            }
        case ADD_TO_CART:
            const selectedProductIds = state.shoppingCart.map(product => product.id);
            if(!selectedProductIds.includes(action.payload)) {
                return {...state, shoppingCart: [...state.shoppingCart, action.payload]};
            }
            return state;
        case REMOVE_FROM_CART:
            const newShoppingCart = state.shoppingCart.filter(product => product.id !== action.payload);
            return {...state, shoppingCart: newShoppingCart};
        case ORDER_SUCCESS:
            window.location.href = action.payload
            return {...state, processingOrder: false, errors: false};
        case ORDER_ERROR:
            return {...state, errors: true};
        case PROCESSING_ORDER:
            return {...state, processingOrder: true, errors: false};
        case SET_USER_INFO:
            return {...state, user: action.payload}
        default:
            return state;
    }
}