import styled from "styled-components";

export const DefaultPageContainer = styled.div`
    min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    padding: 64px 16px 16px;
    text-align: left;
    color: white;
    img {
        height: fit-content;
        margin-top: 32px;
        margin-bottom: 32px;
    }
    h1 {
        font-size: 32px;
        @media only screen and (min-width: 768px) { 
            font-size: 48px;
        }
    }
    p {
        font-weight: 700;
        font-size: 18px;
        margin: 16px 0;
        @media only screen and (min-width: 768px) { 
            font-size: 24px;
        }
    }
    @media only screen and (min-width: 768px) { 
        padding: 128px 64px 64px;
    }
`

export const HomePageContainer = styled.div`
    width: 100%;
    background-image: ${props => `url("${props.bgImage}")`};
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    @media only screen and (min-width: 768px) { 
        padding: 72px 0;
        min-height: ${props => props.height}px;
    }
`

export const HomePageContent = styled.div`
    color: black;
    padding: 16px;
    background-color: rgba(238,238,238,0.7);
    h1 {
        font-size: 32px;
        margin: 32px 0;
    }
    p {
        font-size: 20px;
        margin: 32px 0;
        font-weight: 700;
    }
    @media only screen and (min-width: 768px) { 
        padding: 64px;
        border-radius: 10px;
    }
`

