import { useState  } from "react";
import GridItem from './GridItem';
import { GridImage } from "../styles/Grid.style";
import { ProductInfo } from '../styles/Product.style';
import { Link } from "react-router-dom";

const ProductCard = ({product, showDescription}) => {
    const [showInfo, setShowInfo] = useState(false);

    return(
          <Link to={`/products/${product.id}`}> 
                <GridItem
                  onMouseEnter={() => setShowInfo(true)} 
                  onMouseLeave={() => setShowInfo(false)} >
                  {showInfo && 
                    <ProductInfo>
                      <h2>{product.acf.title}</h2>
                      {showDescription && <p>{product.acf.description}</p>}
                    </ProductInfo>}
                  <GridImage src={product.acf.image.sizes.large}></GridImage>
                </GridItem>
          </Link>
    )
}

export default ProductCard;