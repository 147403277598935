import { useEffect, useState } from "react";
import { CheckoutInfoContainer, ProductImage, ProductInfoContainer, ProductModalContainer, CheckoutCTA, ProductPriceText, CheckoutButton, OtherImagesContainer, OtherProductsContainer, OtherProductsImageGrid } from "../styles/Product.style"
import { useDispatch, useSelector } from "react-redux";
import { addToCart, setOtherProducts, getProducts } from "../actions/checkout";
import { Link } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import ModalWrapper from "../components/ModalWrapper";
import { CloseOutline } from '@styled-icons/evaicons-outline'
import styled from "styled-components";

const CloseIcon = styled(CloseOutline)`
  color: #6A62EA;
  width: 56px;
  height: 56px;
`
const ProductDetail = ({match}) => {
    const productId = Number(match.params.productId);
    const dispatch = useDispatch();
    const products = useSelector(state => state.checkout.products);
    const otherProducts = useSelector(state => state.checkout.otherProducts);
    const shoppingCart = useSelector(state => state.checkout.shoppingCart);
    const [product, setProduct] = useState(null)
    const [extraImages, setExtraImages] = useState(null)
    const [isSelected, setIsSelected] = useState(false);
    const [buyModalOpen, setBuyModalOpen] = useState(false);
    const [imgEnhancerOpen, setImgEnhancerOpen] = useState(false);
    const [enhancedImageSrc, setEnhancedImageSrc] = useState('');

    const handleClick = (e) => {
        e.preventDefault();
        setBuyModalOpen(true);
        if(!isSelected) {
            dispatch(addToCart(product))
        }
    }

    useEffect(() => {
        if(product) {
            const makeExtraImagesArr = (product) => {
                const result = [];
                if(product.acf.extra_images && Object.keys(product.acf.extra_images).length > 0) {
                    Object.keys(product.acf.extra_images).forEach(key => {
                        if(product.acf.extra_images[key]) {
                            result.push(product.acf.extra_images[key])
                        }
                        
                    })
                }
                return result;
            }

            const extraImages = makeExtraImagesArr(product);
            if(extraImages.length > 0) {
                setExtraImages(extraImages);
            }
        }

        if(shoppingCart && product && shoppingCart.length > 0) {
            const foundProduct = shoppingCart.findIndex(item => item.id === product.id) !== -1;
            if(foundProduct) {
                setIsSelected(true);
            } else {
                setIsSelected(false);
            }
        }
    }, [shoppingCart, product])

    useEffect(() => {
      if(products !== undefined && products.length > 0) {
        const found = products.find(product => product.id === productId);
        if(found) {
          setProduct(found);
          dispatch(setOtherProducts(productId))
        } 
      } else {
          dispatch(getProducts())
      }
    }, [products, productId, dispatch])

    const handleImageClick = (imageSizes) => {
        setImgEnhancerOpen(true);
        setEnhancedImageSrc(imageSizes.large);
    }

    return (<>
            <ModalWrapper isOpen={buyModalOpen} overlayColor="rgb(254, 193, 193, 0.75)">
                <div onClick={() => setBuyModalOpen(prev => !prev)} style={{alignSelf: 'flex-end', cursor: 'pointer'}}>
                    <CloseIcon/>
                </div>
                {product && <>
                    <h1>Congratulations!</h1>
                    <p><strong>{product.acf.title}</strong> was added to your shopping cart</p>
                </>}
                <div className="modal-nav-buttons" style={{marginTop: 48, justifySelf: 'flex-end'}}>
                    <Link to="/checkout" onClick={() => setBuyModalOpen(prev => !prev)}>Go to Checkout</Link>
                    <Link to="/shop" onClick={() => setBuyModalOpen(prev => !prev)}>Shop some more</Link>
                </div>
            </ModalWrapper>
            <ModalWrapper isOpen={imgEnhancerOpen}>
                <div onClick={() => setImgEnhancerOpen(prev => !prev)} style={{alignSelf: 'flex-end', cursor: 'pointer'}}>
                    <CloseIcon/>
                </div>
                <img style={{width: '100%', objectFit: 'contain', maxHeight: 640}} src={enhancedImageSrc} alt="enhanced"/>
            </ModalWrapper>
        {product && 
          <ProductModalContainer>
                <div onClick={() => handleImageClick(product.acf.image.sizes)} style={{cursor: 'pointer'}}>
                    <ProductImage src={product.acf.image.sizes.large} alt="product" />
                </div>
                        
                <ProductInfoContainer className="product-info-container">
                    <h1>{product.acf.title}</h1>
                    <p>{product.acf.description}</p>
                    <OtherImagesContainer>
                        {extraImages && extraImages.length > 0 && extraImages.map(image => 
                            <div key={image.id} onClick={() => handleImageClick(image.sizes)} style={{cursor: 'pointer'}}>
                                <ProductImage key={image.id} src={image.sizes.large} alt="product" />
                            </div>
                        )}
                    </OtherImagesContainer>
                </ProductInfoContainer>
                <div style={{maxWidth: '92%'}}>
                    <CheckoutInfoContainer>
                        <div style={{marginBottom: 32}}>
                            <p>{product.acf.title}</p>
                        </div>
                        
                        <CheckoutCTA>
                            <CheckoutButton onClick={handleClick}>BUY NOW</CheckoutButton>
                            <ProductPriceText>
                                <p>€{product.acf.price}</p>
                            </ProductPriceText>
                        </CheckoutCTA>
                    </CheckoutInfoContainer>
                    <OtherProductsContainer style={{backgroundColor: 'white', color: 'black'}}>
                        <h3>Other Products</h3>
                        <OtherProductsImageGrid>
                            {otherProducts && otherProducts.map(product => <ProductCard key={product.id} product={product} showDescription={false} style={{maxHeight: '100px'}}/>)}
                            <Link to="/">Browse all products</Link>
                        </OtherProductsImageGrid>
                    </OtherProductsContainer>
                </div>
          </ProductModalContainer>}
      </>
    );
};

export default ProductDetail