import {SET_PAGES, FIND_PAGE, SET_MEDIA, SET_PAGE } from '../actions/page';

export default function page(state = {}, action = {}) {
    switch (action.type) {
        case SET_PAGES:
            return {...state, allPages: action.payload}
        case SET_PAGE:
            return {...state, singlePage: action.payload}
        case SET_MEDIA:
            return {...state, media: action.payload}
        case FIND_PAGE:
            const foundPage = state.allPages?.find(page => page.slug === action.payload);
            if(foundPage) {
                return {...state, currentPage: foundPage, pageNotFound: false}
            } else {
                return {...state, pageNotFound: true};
            }
        default:
            return state;
    }
}