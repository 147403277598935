import { Link } from "react-router-dom";
import styled from "styled-components";
import { ArrowForwardOutline } from '@styled-icons/evaicons-outline'

const MenuContainer = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: rgba(238,238,238,0.7);
    padding: 64px 16px;
`

const MenuItem = styled.div`
    color: #6A62EA;
    border-bottom: 1px dotted grey;
    font-size: 24px;
    padding: 24px 0;
`
const ArrowRightIcon = styled(ArrowForwardOutline)`
    color: #6A62EA;
    width: 32px;
    height: 32px;
    margin-left: auto;
`

const TopBarMenu = ({items, onItemClick}) => {
    return(
        <MenuContainer>
            {items && items.map((item, i) => 
                <MenuItem key={`menu-item-${i}`}>
                    <Link to={item.href} style={{display: 'flex'}} onClick={onItemClick}>{item.title} <ArrowRightIcon/></Link>
                </MenuItem>
            )}
       </MenuContainer>
    )
};

export default TopBarMenu;