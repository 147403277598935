import qs from "qs";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { removeFromCart, placeOrder } from "../actions/checkout";
import CheckoutForm from "../components/CheckoutForm";
import { addProductLine, getTotalPrice } from "../lib/helpers";
import { CheckoutContainer, ShoppingCartList, ShoppingCartListItem, ShoppingCartListTotal } from "../styles/Checkout.style";
import styled from 'styled-components'
import { Trash } from '@styled-icons/entypo'
import ModalWrapper from "../components/ModalWrapper";

const RemoveIcon = styled(Trash)`
  color: white;
  padding: 6px;
  width: 18px;
  height: 18px;
`

const Checkout = () => {
    const { shoppingCart, processingOrder } = useSelector(state => state.checkout);
    const dispatch = useDispatch();
    
    function orderSelectedProducts(formData) {
        const totalPrice = getTotalPrice(shoppingCart);
        const lines = [];
        shoppingCart.forEach(item => lines.push(addProductLine(item)));
        const date = new Date();
        if(lines.length > 0) {
            dispatch({type: 'PROCESSING_ORDER'})
            const body = {
                'amount[currency]': 'EUR',
                'amount[value]': totalPrice,
                locale: 'nl_NL',
                lines,
                orderNumber: date.getTime().toString(),
                redirectUrl: 'https://pinkis4everyone.com/thank-you',
                webhookUrl: `${process.env.REACT_APP_WP_BASE_URL}/mailer/v1/send?email=${formData.email}`,
                billingAddress: formData,
            };
            dispatch(placeOrder(qs.stringify(body)));
        }
    }

    return(<>
        <ModalWrapper isOpen={processingOrder} overlayColor="rgba(106, 98, 234, .75)">
            <div style={{padding: 32, color: '#6A62EA'}}>
                <h2>Processing your order...</h2>
                <iframe 
                  title="pink-processing" 
                  src="https://giphy.com/embed/12cNrAJQALd9ug" 
                  width="480" 
                  height="266" 
                  frameBorder="0"/>
                <p>One moment please</p>
            </div>
        </ModalWrapper>
        <CheckoutContainer>
            <CheckoutForm fireOrder={orderSelectedProducts}/>
            
            <ShoppingCartList>
                <h2 style={{alignSelf: 'center'}}>Receipt</h2>

                <ul>
                    {shoppingCart && shoppingCart.map(product => 
                        <Link style={{width: '100%'}} to={`/products/${product.id}`} key={product.id}>
                            <ShoppingCartListItem>
                                <img src={product.acf.image.sizes.thumbnail} alt="shopping cart item" style={{maxWidth: 72}}/>
                                <div style={{flex: 1}}>
                                    <h2>{product.acf.title}</h2>
                                </div>  
                                <p>€{product.acf.price}</p>
                                <button onClick={() => dispatch(removeFromCart(product.id))}><RemoveIcon/></button>
                            </ShoppingCartListItem>
                        </Link>)}
                    

                    <ShoppingCartListTotal>
                        <h2>Total</h2>
                        <p>€{getTotalPrice(shoppingCart)}</p>
                    </ShoppingCartListTotal>
                </ul>

                {shoppingCart.length === 0 && <>
                    <p>You have no products selected yet!</p>
                    <Link to="/" style={{color: 'white'}}>Find your style now</Link>
                </>}
            </ShoppingCartList>
        </CheckoutContainer>
      </>
    )
}

export default Checkout;