import axios from "axios"

export const SET_PAGES = 'SET_PAGES';
export const SET_PAGE = 'SET_PAGE';
export const SET_MEDIA = 'SET_MEDIA';
export const FIND_PAGE = 'FIND_PAGE';

export const findPage = (pageSlug) => {
    return {
        type: 'FIND_PAGE',
        payload: pageSlug
    }
}

export const setPages = (payload) => {
    return {
        type: 'SET_PAGES',
        payload
    }
}
export const setMedia = (payload) => {
    return {
        type: 'SET_MEDIA',
        payload
    }
}

export const setPage = (payload) => {
    return {
        type: 'SET_PAGE',
        payload
    }
}

export const getAllPages = () => {
    return (dispatch) => {
        return axios.get(`${process.env.REACT_APP_WP_BASE_URL}/wp/v2/pages`)
                    .then(res => dispatch(setPages(res.data)))
                    .catch(err => console.error(err))
    }
}

export const getPageById = (id) => {
    return (dispatch) => {
        return axios.get(`${process.env.REACT_APP_WP_BASE_URL}/wp/v2/pages/${id}`)
                    .then(res => dispatch(setPage(res.data)))
                    .catch(err => console.error(err))
    }
}

export const getMedia = () => {
    return (dispatch) => {
        return axios.get(`${process.env.REACT_APP_WP_BASE_URL}/wp/v2/media?per_page=100`)
                    .then(res => dispatch(setMedia(res.data)))
                    .catch(err => console.error(err))
    }
}