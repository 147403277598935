import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findPage, getAllPages } from "../actions/page";
import { DefaultPageContainer } from "../styles/Page.style";

const DefaultPage = ({match}) => {
    const pageSlug = match.params.slug;
    const pageStore = useSelector(state => state.page);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!pageStore.allPages) {
            dispatch(getAllPages());
        } 
        dispatch(findPage(pageSlug));
    }, [pageStore.allPages, pageStore.currentPage, pageSlug, dispatch])

    return(
        <div>
            {pageStore && pageStore.currentPage && 
                <>
                    <DefaultPageContainer className="default-page-container" dangerouslySetInnerHTML={{ __html: pageStore.currentPage.content.rendered }}>
                    </DefaultPageContainer>
                </>
            }
            {
                !pageStore.currentPage && <h1>Loading...</h1>
            }
        </div>
    )
}

export default DefaultPage;