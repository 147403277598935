import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.css';
import TopBar from "./components/TopBar";
import Checkout from "./views/Checkout";
import Home from "./views/Home";
import ProductDetail from "./views/ProductDetail";
import DefaultPage from "./views/DefaultPage";
import Shop from "./views/Shop";

const App = () => {
  return (
      <Router>
        <header>
          <TopBar/>
        </header>
        <main className="app">
          <Switch>
            <Route path="/checkout">
              <Checkout/>
            </Route>
            <Route path="/shop">
              <Shop/>
            </Route>
            <Route exact path="/:slug" component={DefaultPage}/>
            <Route exact path="/products/:productId" component={ProductDetail}/>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </main>
      </Router>
  );
}

export default App;